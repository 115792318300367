import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'

const FAQ = () => {
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle="Întrebări frecvente"
                homePageText="Informații utile"
                homePageUrl="/" 
                activePageText="Întrebări frecvente"
            />

            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion allowZeroExpanded preExpanded={['a']}>
                            <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. Ce este dezvoltarea software-ului personalizat?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Dezvoltarea software-ului personalizat este procesul de creare a unui software care se potrivește exact nevoilor unice ale afacerii dvs. În contrast cu software-ul generat în masă, software-ul personalizat este creat în mod specific pentru a se potrivi nevoilor afacerii dvs. și poate fi adaptat și modificat în funcție de schimbările care apar în afacerile dvs.
                                     </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. De ce aș alege să dezvolt software personalizat pentru afacerile mele?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                            Software-ul personalizat poate oferi o mulțime de avantaje pentru afacerile dvs., inclusiv o potrivire exactă a nevoilor dvs., o mai bună securitate și control, și o mai mare flexibilitate și adaptabilitate. De asemenea, software-ul personalizat poate fi mai economic pe termen lung decât software-ul generat în masă.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="c">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. Ce trebuie să fac pentru a începe procesul de dezvoltare a software-ului personalizat pentru afacerile mele?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Pentru a începe procesul de dezvoltare a software-ului personalizat pentru afacerile dvs., trebuie să contactați un furnizor de dezvoltare software care poate ajuta. Furnizorul va lucra cu dvs. pentru a înțelege nevoile afacerii dvs. și va dezvolta un plan pentru crearea software-ului personalizat.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="d">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. Ce se întâmplă după ce software-ul a fost dezvoltat?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        După ce software-ul a fost dezvoltat, furnizorul de dezvoltare software va oferi suport și mentenanță pentru a vă asigura că software-ul funcționează corect și că toate problemele sunt rezolvate rapid. De asemenea, furnizorul poate oferi opțiuni de adaptare și modificare a software-ului în funcție de schimbările care apar în afacerile dvs.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="e">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. Pot face modificări la software-ul personalizat după ce a fost dezvoltat?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Da, software-ul personalizat poate fi adaptat și modificat după ce a fost dezvoltat. Acest lucru înseamnă că software-ul poate evolua împreună cu afacerile dvs. și poate rămâne relevant și eficient pe termen lung.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        <AccordionItem uuid="f">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q6. Care este diferența dintre software-ul personalizat și SaaS?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Software-ul personalizat este proiectat în mod specific pentru a se potrivi nevoilor unice ale afacerii dvs. În contrast, SaaS (Software as a Service) este un tip de software generat în masă care este oferit ca un serviciu prin abonament și care nu poate fi adaptat în mod specific nevoilor afacerii dvs.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>


                        <AccordionItem uuid="g">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q7. Care sunt beneficiile externalizării software către o companie din România?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                               România are o forță de muncă foarte educată și calificată, cu mulți profesioniști pregătiți în informatică și IT.  România are o tradiție puternică în dezvoltarea de software și un număr mare de profesioniști talentați, ceea ce o face o locație ideală pentru externalizarea software-ului.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        <AccordionItem uuid="h">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q8. Cum aleg partenerul de dezvoltare software potrivit?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Atunci când alegeți un partener de dezvoltare de software, este important să luați în considerare factori precum experiența și istoricul companiei, capacitățile și expertiza acesteia și capacitatea sa de a furniza soluții software de înaltă calitate la timp și la buget. De asemenea, este important să luați în considerare procesele de comunicare și management de proiect ale companiei, precum și compatibilitatea practicilor sale de afaceri cu ale dumneavoastră.                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>

            <StartProject />
            
            <Footer />
        </Layout>
    );
}

export default FAQ